import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/atoms/EnrollButton/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/atoms/SideButtons/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/FooterLinks/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/NewsletterForm/NewsletterForm.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/organisms/Footer/footer.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/organisms/Header/index.tsx")