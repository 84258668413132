"use client";

import { useContext, useEffect, useState } from "react";
import Button from "@/components/atoms/Button";
import { usePlatformAndOS } from "@/helpers/usePlatformAndOS";
import { useDeviceInfo } from "@/helpers/userDeviceType";
import { getGAPayload } from "@/helpers/getGAPayload";
import { IModalActions, ModalTypes } from "@/types/modal";
import { ModalContext } from "@/context/ModalContext";
import { useQueryParams } from "@/helpers/useQueryParams";

interface CommonButtonProps {
  href?: string;
  CTATarget?: string;
  GAData?: Record<string, any>;
  children: React.ReactNode;
  size?: string;
  bg?: string;
  color?: string;
  icon?: string;
  hoverIcon?: string;
  className?: any;
  type?: 'submit' | 'button' | 'link'
  disabled?: boolean;
  scroll?: boolean;
  imageLoadingStrategy?: "lazy" | "eager";
  pageType?: string;
  modalData?:{},
  modalType?:ModalTypes,
  programTitle?:string
  isEventOnly?: boolean;
  closeModal?: boolean;
}

const CommonButton: React.FC<CommonButtonProps> = ({
  href = "",
  CTATarget,
  GAData,
  children,
  size = "",
  bg = "",
  color = "",
  icon = "",
  hoverIcon = "",
  className = "",
  type = "",
  disabled = false,
  scroll = false,
  imageLoadingStrategy = "",
  pageType = "",
  isEventOnly = false,
  modalData,
  modalType,
  programTitle,
  closeModal = false
}) => {
  const queryParams = useQueryParams();
  const { platform = "", version = "" } = usePlatformAndOS()
  const deviceType = useDeviceInfo()
  const [pageTitle, setPageTitle] = useState<string>("")
  const [pageReferrer, setPageReferrer] = useState<string>("")
  const { modalDispatch } = useContext(ModalContext)

  useEffect(() => {
    setPageReferrer(document.referrer)
    setPageTitle(document.title)
    const handleTitleChange = () => {
      setPageTitle(document.title)
    }
    document.addEventListener("titlechange", handleTitleChange)
    return () => {
      document.removeEventListener("titlechange", handleTitleChange)
    }
  }, [])

  const handleGaClick = () => {
    // sending only event data in datalayer
    if (
      isEventOnly &&
      (window as any).dataLayer &&
      Object.keys(GAData).length > 0 &&
      GAData.event &&
      GAData.event !== ""
    ) {
      (window as any).dataLayer.push(GAData);
      return
    }

    // sending other parameters in datalayer
    let pageUrl = href
    if (!pageUrl.startsWith("http://") && !pageUrl.startsWith("https://")) {
      if (pageType === "origin") {
        const origin = window.location.origin
        pageUrl = `${origin}/${pageUrl}`
      } else {
        const fullUrl = window.location.href
        pageUrl = `${fullUrl}`
      }
    }
    const otherInfo = {
      platform,
      deviceType,
      version,
      pageTitle,
      pageReferrer,
      pageUrl,
    };
    
    if (Object.keys(GAData).length > 0 && GAData.event && GAData.event !== "") {
      const payload = getGAPayload(GAData, queryParams, otherInfo)
      if (typeof window !== "undefined" && (window as any).dataLayer) {
        const existingEventIndex = (window as any).dataLayer.findIndex(
          (e: any) => e.event === payload.event
        )
        if (existingEventIndex === -1) {
          ;(window as any).dataLayer.push(payload)
        }
      }
    }
  }

  function curry(...funcArgs: any[]) {
    return () => {
      if (funcArgs.length > 0) {
        funcArgs.forEach((func) => func())
      }
    }
  }

  function getButtonProps({
    href,
    CTATarget,
    GAData,
    type,
    closeModal,
    modalData,
    programTitle
  }) {
    let buttonProps: any = {}
    let modalAction = () => {}
    const applyGaEvent =
      GAData && Object.keys(GAData).length > 0 && GAData.event !== ""
    if (href && type !== "submit") {
      buttonProps = {
        ...buttonProps,
        href,
        scroll,
        target: `${CTATarget || "self"}`,
      }
    }
    if (type === "button" && !closeModal) {
      modalAction = () =>
        modalDispatch({
          type: IModalActions.OpenModal,
          data: {
            modalType,
            modalData: { ...modalData, url: href, programTitle },
          },
        })
    }
    if (closeModal) {
      modalAction = () =>
        modalDispatch({
          type: IModalActions.CloseModal,
        })
    }
    if (applyGaEvent) {
      buttonProps.onClick = curry(modalAction, modalAction, handleGaClick);
    }else{
      buttonProps.onClick = curry(modalAction, modalAction);
    }
    return buttonProps
  }

  const buttonProps = getButtonProps({
    href,
    CTATarget,
    GAData,
    type,
    closeModal,
    modalData,
    programTitle
  })

  return (
    <>
      <Button
        buttonProps={buttonProps}
        size={size}
        bg={bg}
        color={color}
        icon={icon}
        hoverIcon={hoverIcon}
        className={className}
        type={type}
        disabled={disabled}
        imageLoadingStrategy={imageLoadingStrategy}
      >
        {children}
      </Button>
    </>
  )
}
export default CommonButton;
